import * as React from "react"
import PropTypes from "prop-types"

const LinkWrapper = ({ children, extraClasses, title }) => (
  <div className={`link-wrapper ${extraClasses}`}>
      <h2 class="link-wrapper__title" dangerouslySetInnerHTML={{__html : title }}></h2>
      {children}
  </div>
)

LinkWrapper.propTypes = {
    url: PropTypes.string,
    extraClasses: PropTypes.string,
    title: PropTypes.string,
}

LinkWrapper.defaultProps = {
    url: ``,
    extraClasses: ``,
    title: ``
}

export default LinkWrapper