import React, { useState } from 'react';
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
export default function Video({ url }) {

    const [videoActive, setVideoActive] = useState(false);

    return(
    <div className="video-wrapper video-wrapper--pull-up-mobile">
            
            {videoActive 
            ? <iframe 
                class="lazyframe"
                src={url} 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen 
                loading="lazy" >
            </iframe>
            :
            <>
             <button className="video-wrapper__placeholder" aria-label="Start videoavspiller" title="Start videoavspiller" onClick={ () => setVideoActive(true) } >
                 <div className="icon">
                     <svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M50.9997 11.22C29.0422 11.22 11.2197 29.0425 11.2197 51C11.2197 72.9576 29.0422 90.78 50.9997 90.78C72.9573 90.78 90.7797 72.9576 90.7797 51C90.7797 29.0425 72.9573 11.22 50.9997 11.22ZM50.9997 13.26C71.8536 13.26 88.7397 30.1461 88.7397 51C88.7397 71.8539 71.8536 88.74 50.9997 88.74C30.1458 88.74 13.2597 71.8539 13.2597 51C13.2597 30.1461 30.1458 13.26 50.9997 13.26ZM39.7797 32.64V69.36L70.3797 51L39.7797 32.64Z" fill="white"/>
                     </svg>
                </div>
                <StaticImage src="../images/placeholder.jpg" alt="" width="787" height="442" />
             </button>
            </>
            }
    </div>
    )
}

Video.propTypes = {
    url: PropTypes.string,
}

Video.defaultProps = {
    url: ``,
}