import React from "react"
import Layout from "../components/layout"
import Video from "../components/video"
import CircleQuote from "../components/circlequote"
import LinkWrapper from "../components/linkwrapper"
import LinkWrapperLink from "../components/linkwrapperlink"
import Tabs from "../components/tabs"
import Seo from "../components/seo"
import Header from "../components/header"
import Helmet from 'react-helmet';
import shareImage from '../images/share.jpg';


export default function IndexPage() {
    
    return (
        <>
        <Layout>
            <Helmet>
                <meta name="facebook-domain-verification" content="0thrtmls827qaiqs7irf1c1dytaeso" />
            </Helmet>
            <Seo 
                title="Sjekksynet.no"
                image={shareImage}
             />
            <Header />
            <main class="container">
            <div className="content">
                <div className="content__main">
                    <Video url="https://www.youtube.com/embed/OOBSUOc835g?autoplay=1&rel=0"></Video>
                    <LinkWrapper title="Bestill time for synsundersøkelse" extraClasses="mobile-only">
                        <LinkWrapperLink title="Interoptik" url="https://www.interoptik.no/bestill-time"></LinkWrapperLink>
                    </LinkWrapper>
                    <Tabs tab={1}></Tabs>
                </div>
                <aside className="content__sidebar">
                    <LinkWrapper title="Bestill time for synsundersøkelse" extraClasses="desktop-only">
                        <LinkWrapperLink title="Interoptik" url="https://www.interoptik.no/bestill-time"></LinkWrapperLink>
                    </LinkWrapper>
                    <LinkWrapper extraClasses="link-wrapper--green" title="Mer informasjon om grønn stær (glaukom)">
                        <LinkWrapperLink title="Norsk Glaukomforening (pasientorganisasjon)" url="https://glaukomforeningen.no/"></LinkWrapperLink>
                        <LinkWrapperLink title="Santen" url="https://www.santen.no/"></LinkWrapperLink>
                    </LinkWrapper>
                    <CircleQuote quote="Grønn stær utvikles langsomt. Det gjør det vanskelig å oppdage tegnene selv."></CircleQuote>
                </aside>
            </div>
            </main>
        </Layout>
        </>
    )
}