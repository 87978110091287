import * as React from "react"
import PropTypes from "prop-types"
import EyeIcon from "../images/icons/eye.inline.svg"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <div className="header">
     
      <div className="header__inner">
          <div className="header__logo"><EyeIcon/> #sjekksynet</div>
          <h1 class="is-light-yellow">Grønn stær:<br/>Ikke vær <em>etter snar</em></h1>
          <p>Grønn stær (glaukom) kan ikke reverseres. Går du sjelden/aldri til synskontroll? Eller har du grønn stær i familien? Da burde du være <em class="u-strong">føre var</em> og gå til fastlegen eller få sjekket øyehelsen din hos optiker.<sup>1,2</sup></p>
      </div>
      <div className="header__image">
          <div className="desktop-only">
            <StaticImage src="../images/overlay.png" alt="" className="overlay"/>
            <StaticImage src="../images/header.jpg" quality="75" alt="Bestemor og barnebarn på tur i skogen" className="image-animation"/>
          </div>
          <div className="mobile-only">
            <div className="overlay-overlay"></div>
            <StaticImage src="../images/overlay-mobile.png" alt="" className="overlay overlay--mobile"/>
            <StaticImage src="../images/header-mobile.jpg" quality="75" alt="Bestemor og barnebarn på tur i skogen" className="image-animation image-animation--mobile"/>
          </div>
      </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
