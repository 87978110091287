import * as React from "react"
import PropTypes from "prop-types"

const LinkWrapperLink = ({ url, title }) => (
  <a className="link-wrapper-link" target="_blank" href={url} rel="noreferrer">
      {title}
  </a>
)

LinkWrapperLink.propTypes = {
    url: PropTypes.string,
}

LinkWrapperLink.defaultProps = {
    url: ``,
}

export default LinkWrapperLink