import * as React from "react"
import PropTypes from "prop-types"
import EyeIcon from "../images/icons/eye.inline.svg"

const CircleQuote = ({ quote }) => (
    <div className="circle-quote">
        <div className="circle-quote__inner">
            <EyeIcon/>
            {quote}
        </div>
    </div>
)

CircleQuote.propTypes = {
    quote: PropTypes.string,
}

CircleQuote.defaultProps = {
    quote: ``,
}

export default CircleQuote