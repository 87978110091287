import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

export default function Tabs({ tab }) { 

const [currentTab, setCurrentTab] = useState(tab ? tab : 1);
useEffect(() => { setCurrentTab(tab); },[tab])

const activeTab = (e, x) => {
    setCurrentTab(x)
    const btns = document.querySelectorAll('.tab-button');
    btns.forEach((btn) => { 
        btn.classList.remove('is-active')
        btn.setAttribute('aria-selected', 'false');
    });
    e.target.classList.add('is-active');
    e.target.setAttribute('aria-selected', 'true');
    
}

return(
  <div className="tabs" role="tablist" aria-label="Tabs">
      <div className="tabs__header">
          <button className="tab-button is-active" id="tab-1" role="tab" aria-selected="true" title="Les mer om sykdom" onClick={ (e) => activeTab(e,1) }>Sykdom</button>
          <button className="tab-button" id="tab-2" role="tab" aria-selected="false" title="Les mer om symptomer" onClick={ (e) => activeTab(e,2) }>Symptomer</button>
          <button className="tab-button" id="tab-3" role="tab" aria-selected="false" title="Les mer om behandling" onClick={ (e) => activeTab(e,3) }>Behandling</button>
      </div>
      <div className="tabs__content">
      {currentTab === 1 ? (
            <div role="tabpanel" tabIndex="0" aria-labelledby="tab-1">
                <h2>Hva er grønn stær (glaukom)?</h2>
                <p>Grønn stær (glaukom) er en betegnelse på en gruppe sykdommer som forårsaker en fremskridende skade på synsnerven i øyebunnen. Ubehandlet vil dette, over tid, gi en reduksjon av synsfeltet ditt.<sup>1,2</sup></p>
                <p class="p-before-ul"><strong>Det finnes ulike former for grønn stær:</strong></p>
                <ul>
                    <li>Kronisk grønn stær (primært åpenvinkelglaukom) er den vanligste formen</li>
                    <li>Akutt grønn stær (trangvinkelglaukom), normaltrykksglaukom, sekundærglaukom og medfødt glaukom er andre former.</li>
                </ul>
                <p>På disse sidene omtaler vi i hovedsak kronisk grønn stær (primært åpenvinkelglaukom). Hos personer med denne formen for grønn stær, er det vanligvis en langsom utvikling av skaden på synsnerven. Det betyr at synsfeltet reduseres nærmest «umerkelig» med tiden. Når det først blir konstatert bortfall av synsfelt, kan glaukomet dermed være langt fremskredet.</p>
                <p>Ubehandlet glaukom kan med andre ord resultere i alvorlig synsnedsettelse, eller til og med blindhet – med mindre du er «føre var» og går jevnlig til øyeundersøkelse.<sup>1,2</sup></p>
            </div>
        ) : currentTab === 2 ? (
            <div role="tabpanel" tabIndex="0" aria-labelledby="tab-2">
                <h2>Symptomer på grønn stær</h2>
                <p>Grønn stær svekker synet svært langsomt, men den snikende skaden som oppstår, kan ikke repareres. Synsnerveskaden forårsaker ikke smerter, og man merker vanligvis ikke påvirkningen på synsfeltet i et tidlig stadium av sykdommen. Dette gjør tilstanden vanskelig å oppdage i tide på egenhånd.</p>
                <ul class="p-before-ul">
                    <li>Sykdommen starter som regel med små blindsoner i utkanten av synsfeltet.</li>
                    <li>Ubehandlet vil disse blindsonene vokse seg sakte større, noe som kan resultere i alvorlig synsnedsettelse, eller til og med blindhet.<sup>1,2</sup></li>
                </ul>

                <p><strong>Hvem er i risikogruppen for å få grønn stær?</strong><br/>
                Sykdommen er vanligst blant personer over 60 år. Hvis en nær slektning (foreldre/søsken) har grønn stær, er risikoen for å få sykdommen mange ganger høyere enn ellers. </p>
               <p>Tilhører du en risikogruppe og går sjelden eller aldri til øyelege/optiker, kan en synsundersøkelse være et viktig «føre var»-tiltak. <sup>1,2</sup></p>
            </div>
        ) : (
            <div role="tabpanel" tabIndex="0" aria-labelledby="tab-3">
                <h2>Behandling av grønn stær</h2>

                <p>Årsaken til grønn stær er fremdeles lite kjent, men langvarig forhøyet øyetrykk er den vanligste risikofaktoren. Grønn stær kan ikke helbredes, og synsfeltforandringene som følge av sykdommen er varige. Det vil dermed være viktig å holde sykdommen under kontroll slik at den ikke forverrer seg. På den måten kan man bremse utviklingen av skader på synsnerven og synsfeltbortfall.</p>
                
                <p><strong>Øyedråper mot grønn stær</strong><br/>
                Legen tar stilling til hvilken type behandling som er egnet. Ved valg av behandling tar man hensyn til sykdommens art og alvorlighetsgrad, din alder, andre eventuelle sykdommer og medisiner. Målsettingen med behandlingen er å senke øyetrykket, og den innledes derfor vanligvis med trykksenkende øyedråper. Det er også avgjørende at du ikke glemmer å ta øyedråpene du er tilskrevet.</p>
                <p>Hvis øyedråpene ikke har ønsket effekt, eller dersom de gir betydelige bivirkninger, kan andre øyedråper testes. Ettersom det er en langvarig behandling kan du be legen om å forskrive øyedråper uten konserveringsmiddel. Konserveringsfrie øyedråper på flaske kan være bra dersom du synes det vanskelig å håndtere pipetter <sup>3</sup> Laserbehandling eller operasjon kan også være en løsning for enkelte. </p>

                <p><strong>Vær «føre var» med en øyeundersøkelse</strong><br/>
                Siden grønn stær kommer snikende, er vanskelig å avdekke på egenhånd og oppstått skade er varig, er det viktig å være «føre var». Det gjør du best ved å regelmessig oppsøke fastlege/øyelege eller optiker for blant annet å få målt trykket. Slik kan du eventuelt få tidlig behandling. <sup>1,2</sup></p>
            </div>
        )}
      </div>
  </div>
)
}

Tabs.propTypes = {
    tab: PropTypes.number,
}

Tabs.defaultProps = {
    tab: 1,
}
